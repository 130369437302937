import { gql } from '@apollo/client';
import {
  UserFragment,
  AdvertisementFragment,
  OrderFragment,
  ShipmentFragment,
  FreightFragment,
  PaymentMethodFragment,
  PaymentFragment,
  PromotionFragment,
  QuotationFragment,
} from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

// Advertisements
export const GET_ADVERTISEMENT = gql`
  query GET_ADVERTISEMENT($filter: FilterFindOneAdvertisementInput) {
    advertisement(filter: $filter) {
      ...AdvertisementFragment
    }
  }
  ${AdvertisementFragment}
`;

export const GET_ADVERTISEMENTS = gql`
  query GET_ADVERTISEMENTS(
    $filter: FilterFindManyAdvertisementInput
    $sort: SortFindManyAdvertisementInput
  ) {
    advertisements(filter: $filter, sort: $sort) {
      ...AdvertisementFragment
    }
  }
  ${AdvertisementFragment}
`;

// Orders
export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput) {
    order(filter: $filter) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

// limit 2000
export const GET_ORDERS = gql`
  query GET_ORDERS(
    $filter: FilterFindManyOrderInput
    $sort: SortFindManyOrderInput
  ) {
    orders(filter: $filter, sort: $sort, limit: 2000) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const GET_SHIPMENT = gql`
  query GET_SHIPMENT($filter: FilterFindOneShipmentInput) {
    shipment(filter: $filter) {
      ...ShipmentFragment
    }
  }
  ${ShipmentFragment}
`;

export const GET_SHIPMENTS = gql`
  query GET_SHIPMENTS(
    $filter: FilterFindManyShipmentInput # $sort: SortFindManyOrderInput
  ) {
    shipments(filter: $filter) {
      ...ShipmentFragment
    }
  }
  ${ShipmentFragment}
`;

export const GET_FREIGHT = gql`
  query GET_FREIGHT($filter: FilterFindOneFreightInput) {
    freight(filter: $filter) {
      ...FreightFragment
    }
  }
  ${FreightFragment}
`;

export const GET_FREIGHTS = gql`
  query GET_FREIGHTS(
    $filter: FilterFindManyFreightInput
    $sort: SortFindManyFreightInput
  ) {
    freights(filter: $filter, sort: $sort) {
      ...FreightFragment
    }
  }
  ${FreightFragment}
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS(
    $filter: FilterFindManyPaymentMethodInput
    $sort: SortFindManyPaymentMethodInput
  ) {
    paymentMethods(filter: $filter, sort: $sort) {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragment}
`;

export const FIND_FREIGHT_BY_CODE = gql`
  query FIND_FREIGHT_BY_CODE($data: FindFreightByCodeInput) {
    findFreightByCode(data: $data) {
      ...FreightFragment
    }
  }
  ${FreightFragment}
`;

export const FIND_ORDER_BY_CODE = gql`
  query FIND_ORDER_BY_CODE($data: FindOrderByCodeInput) {
    findOrderByCode(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

// payments query
export const GET_PAYMENT = gql`
  query GET_PAYMENT($filter: FilterFindOnePaymentInput) {
    payment(filter: $filter) {
      _id
      reference
      paymentDate
      method {
        _id
        name
      }
      freight {
        _id
        code
      }
      quotation {
        _id
        code
      }
      amount
      image
      status
      details {
        name
        value
      }
    }
  }
`;

export const GET_RECEPTION_REGIONS = gql`
  query GET_RECEPTION_REGIONS(
    $filter: FilterFindManyReceptionRegionInput
    $sort: SortFindManyReceptionRegionInput
  ) {
    receptionRegions(filter: $filter, sort: $sort) {
      receptionPlaces {
        name
        value
        _id
        receptionRegion
      }
      rates {
        minCbm
        maxCbm
        rate
      }
      key
      _id
      name
    }
  }
`;

export const GET_PAYMENTS = gql`
  query GET_PAYMENTS(
    $filter: FilterFindManyPaymentInput
    $sort: SortFindManyPaymentInput
  ) {
    payments(filter: $filter, sort: $sort) {
      _id
      createdAt
      reference
      paymentDate
      method {
        _id
        name
      }
      freight {
        _id
        code
      }
      quotation {
        _id
        code
      }
      amount
      image
      status
      details {
        name
        value
      }
    }
  }
`;

export const GET_RATE = gql`
  query GET_RATE($filter: FilterFindOneRateInput) {
    rate(filter: $filter) {
      _id
      value
    }
  }
`;

export const GET_PAYMENTS_FOR_FREIGHT = gql`
  query GET_PAYMENTS_FOR_FREIGHT($freightId: String!) {
    getPaymentsForFreight(freightId: $freightId) {
      ...PaymentFragment
    }
  }
  ${PaymentFragment}
`;

export const GET_PROMOTION = gql`
  query GET_PROMOTION(
    $filter: FilterFindOnePromotionInput
    $skip: Int
    $sort: SortFindOnePromotionInput
  ) {
    promotion(filter: $filter, skip: $skip, sort: $sort) {
      ...PromotionFragment
    }
  }
  ${PromotionFragment}
`;

export const GET_FREIGHT_WITH_LOAD = gql`
  query GET_FREIGHT_WITH_LOAD($data: GetFreightWithLoadInput) {
    getFreightWithLoad(data: $data) {
      freight {
        ...FreightFragment
      }
      load {
        _id
        blNumber
        code
        arrivalDate
        containerNumber
        departureDate
        currentLocation
        createdAt
        destination
        progress
        shippingType
        status
        marineTrafficShipId
      }
    }
  }
  ${FreightFragment}
`;

export const GET_QUOTATIONS = gql`
  query GetQuotations(
    $filter: FilterFindManyQuotationInput
    $sort: SortFindManyQuotationInput
  ) {
    quotations(filter: $filter, sort: $sort) {
      ...QuotationFragment
    }
  }
  ${QuotationFragment}
`;

export const GET_QUOTATION = gql`
  query GetQuotation(
    $filter: FilterFindOneQuotationInput
    $sort: SortFindOneQuotationInput
  ) {
    quotation(filter: $filter, sort: $sort) {
      ...QuotationFragment
    }
  }
  ${QuotationFragment}
`;

export const GET_QUOTATION_DETAIL = gql`
  query GET_QUOTATION_DETAIL($data: GetQuotationDetailInput) {
    getQuotationDetail(data: $data) {
      quotation {
        _id
        code
        user
        items {
          quantity
          description
          type
          unitRate
          total
        }
        total
        paidAmount
        remainingAmount
        totalLocalCurrency
        paidAmountLocalCurrency
        remainingAmountLocalCurrency
        notes
        status
        paymentStatus
        createdAt
        updatedAt
      }
      user {
        slug
        email
        stripeId
        password
        firstName
        lastName
        locale
        profilePicture
        permissions {
          name
          key
          option
          active
          _id
          createdAt
          updatedAt
        }
        userType
        phone
        emailVerify
        resetTokenValidity
        resetToken
        dni
        dniType
        sessions {
          user
          token
          device
          active
          _id
          createdAt
          updatedAt
        }
        active
        birthDate
        profession
        occupation
        gender
        socialId
        larkId
        addresses {
          firstLine
          secondLine
          state
          city
          postalCode
          _id
        }
        subscribed
        _id
        createdAt
        updatedAt
      }
      confirmedPayments {
        reference
        larkId
        image
        method {
          name
          currency {
            name
            symbol
            rate
            active
            _id
            createdAt
            updatedAt
          }
          commission
          details {
            name
            value
            _id
          }
          logo
          active
          _id
          createdAt
          updatedAt
        }
        freight {
          code
          larkId
          freightRate
          shippingType
          arrivalDate
          departureDate
          status
          origin
          destination
          currentLocation
          history {
            title
            movementType
            date
            location {
              name
              country
              description
              active
              _id
              createdAt
              updatedAt
            }
            container {
              name
              description
              currentLocation
              status
              lastActivity
              containerHistory {
                containerShippingType
                date
                activity
                location
                vessel
                aircraft
                shippingCompany
                _id
              }
              url
              active
              _id
              createdAt
              updatedAt
            }
            status
            vessel {
              name
              vesselCode
              lloydsNumber
              country
              buildIn
              callSign
              lineCode
              operator {
                name
                description
                website
                contactName
                contactEmail
                logo
                active
                _id
                createdAt
                updatedAt
              }
              active
              _id
              createdAt
              updatedAt
            }
            shippingCompany {
              name
              description
              website
              contactName
              contactEmail
              logo
              active
              _id
              createdAt
              updatedAt
            }
            aircraft {
              aircraftCode
              registrationNumber
              airline
              manufacturer
              model
              maxPayload
              active
              _id
              createdAt
              updatedAt
            }
            active
            arrivalDate
            _id
            createdAt
            updatedAt
          }
          orders {
            code
            qrCode
            larkId
            inFreight
            invoiceNumber
            finalCbm
            paymentStatus
            shipment {
              firstName
              lastName
              dni
              cbm
              email
              dangerous
              replica
              phone
              addressUrl
              postalCode
              shippingType
              merchType
              boxesNumber
              width
              length
              height
              weight
              originCity
              trackingNumber
              boxesPictures
              packingList
              invoice
              shippingCost
              taxes
              productType
              deliveryInstructions
              active
              approved
              _id
              createdAt
              updatedAt
            }
            status
            shippingType
            modality
            promotions {
              name
              type
              modifier
              code
              active
              _id
              createdAt
              updatedAt
            }
            pickupLocation {
              name
              address {
                firstLine
                secondLine
                state
                city
                postalCode
                isFiscal
                _id
              }
              phone
              email
              active
              _id
              createdAt
              updatedAt
            }
            approved
            customerSupport {
              slug
              email
              stripeId
              password
              firstName
              lastName
              locale
              profilePicture
              userType
              phone
              emailVerify
              resetTokenValidity
              resetToken
              dni
              dniType
              active
              birthDate
              profession
              occupation
              gender
              socialId
              larkId
              subscribed
              _id
              createdAt
              updatedAt
            }
            active
            finalWidth
            finalLength
            finalHeight
            finalWeight
            finalShippingCost
            finalTaxes
            receivedImages
            inContainer
            _id
            createdAt
            updatedAt
          }
          cost
          paymentStatus
          active
          paidAmount
          replicaRate
          dangerousRate
          inContainer
          isHeavy
          heavyRate
          totalDangerousCbm
          totalReplicaCbm
          dangerousCost
          replicaCost
          heavyCost
          totalCbm
          hasDiscount
          discount
          hasPenalization
          penalization
          isForeign
          foreignRate
          customsAgency
          destinationTaxes
          _id
          createdAt
          updatedAt
        }
        quotation {
          code
          items {
            quantity
            description
            type
            unitRate
            total
            _id
          }
          total
          notes
          status
          _id
          createdAt
          updatedAt
        }
        multipleFreights
        amount
        status
        type
        details {
          name
          value
          _id
        }
        initiatedByAdmin
        admin {
          slug
          email
          stripeId
          password
          firstName
          lastName
          locale
          profilePicture
          userType
          phone
          emailVerify
          resetTokenValidity
          resetToken
          dni
          dniType
          active
          birthDate
          profession
          occupation
          gender
          socialId
          larkId
          subscribed
          _id
          createdAt
          updatedAt
        }
        active
        paymentDate
        _id
        createdAt
        updatedAt
        freights {
          code
          larkId
          freightRate
          shippingType
          arrivalDate
          departureDate
          status
          origin
          destination
          currentLocation
          cost
          paymentStatus
          active
          paidAmount
          replicaRate
          dangerousRate
          inContainer
          isHeavy
          heavyRate
          totalDangerousCbm
          totalReplicaCbm
          dangerousCost
          replicaCost
          heavyCost
          totalCbm
          hasDiscount
          discount
          hasPenalization
          penalization
          isForeign
          foreignRate
          customsAgency
          destinationTaxes
          _id
          createdAt
          updatedAt
        }
      }
      rate
    }
  }
`;
